import React from "react";
import styled from "styled-components";
import { FlexDiv } from "../design-system/FlexDiv";
import blogPostImg from "../images/blogPostImg2.jpg";
import { Link } from "gatsby";
import blogPostImg22 from "../images/blogPostImg.jpg";
import blogPostImg11 from "../images/blogPostImg.jpg";
import blogPostImg2 from "../images/blogPostImg2.jpg";
import blogPostImg3 from "../images/blogPostImg3.jpg";
import blogPostImg4 from "../images/blogPostImg4.jpg";
import blogPostImg5 from "../images/blogPostImg5.jpg";
import blogPostImg6 from "../images/pandemicera.jpg";
const BlogDetails = styled.p`
  color: gray;
`;
const BlogTitle = styled.p`
  font-size: 1.25em;
  font-family: CentraNo2-Medium;
  margin-bottom: 0px !important;
  margin-right: 4em !important;
`;
const blogExcerpt = styled.p``;
const GalleryWrapper = styled.div`
  margin: auto;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-flow: column;
  max-width: 90%;
`;
const CTATitle = styled.h3`
  font-family: CentraNo2-Bold;
  letter-spacing: 1px;
  font-size: 2.25rem;
  color: #212121;
  text-align: center;
  margin: 0.5em 0;
`;
const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin-bottom: 0px;
  margin-top: 0px;
`;
const Pages = styled.h1`
  font-family: CentraNo2-Book;
  font-size: 45px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: -0.68px;
  text-align: left;
  color: #ffffff;
  margin-right: 16px;
`;
const Rectangle = styled.div`
  width: 27.5em;
  height: 25em;
  opacity: 0.9;
  background-color: #1c1c1c;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 150px;
  display: flex;
  flex-direction: column;
  padding: 0 35px;
`;
const Info = styled.div`
  margin: 0px;
  color: white;
  display: flex;
  // background: red;
  justify-content: space-between;
`;
const NoPages = styled.h1`
  font-family: CentraNo2-thin;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 4.58;
  letter-spacing: -0.36px;
  text-align: left;
  color: #adadad;
`;
const Arrows = styled.div`
  margin-top: 10%;
  // margin-left: 50px;
`;
const GivesBackWrapper = styled.div`
  margin: auto;
  padding: 5em;
  display: inline-flex;
`;
const GivesBackTitle = styled.h3`
  font-family: CentraNo2-Bold;
  font-size: 2em;
  margin-bottom: 0.5em;
`;
const GivesBackText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1.15em;
  line-height: 1.75em;
  font-style: italic;
`;
const GivesBackText2 = styled.p`
  font-family: CentraNo2-Book;
  font-size: 1em;
  line-height: 1.25em;
`;
const ButtonLearnMore = styled.button`
  background: #000;
  color: #fff;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
`;
const ButtonLearnMore2 = styled.button`
  background: #fff;
  color: #000;
  padding: 15px;
  border: none;
  margintop: 20px;
  font-family: CentraNo2-Book;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  align-self: center;
  border: 1.5px solid #000;
`;
const BackSearchLink = styled.a`
  font-family: CentraNo2-Book;
  font-weight: 500;
  margin: 1em 0 2em;
  cursor: pointer;
`;
export const TrueColoursLeadership: React.FunctionComponent = () => {
  return (
    <GivesBackWrapper className="blogPostWrapper">
      <FlexDiv style={{maxWidth: '75%', margin: 'auto'}} className="blogPostContainer">
        <FlexDiv
          style={{
            flexFlow: "column",
            maxWidth: "65%",
            marginRight: "7.5%",
            flex: "100%",
          }}
          className="blogInner"
        >
          <GivesBackTitle className="blogTitle">
            It Is Time For Our True Colors To Illustrate True Leadership And
            Connection
          </GivesBackTitle>
          <GivesBackText2 style={{ margin: 0 }} className="dateText">
            May 28, 2020, 07:30am EDT
          </GivesBackText2>
          <GivesBackText className="authorBio">
            Anthony Tropea, President & Co-Founder of Ellicott Realty Group,
            overseeing the company's portfolio: Ellicott Realty, Ellicott
            Financial, & MacKenzie Hall.
          </GivesBackText>
          <img
            src={blogPostImg}
            style={{
              width: "100%",
              margin: "auto",
              padding: "1em 0",
            }}
          />
          <GivesBackText2>
            "Your true colors are showing." Too often, this idiom refers to the
            image of a person when they are mad or challenged. If we demonstrate
            integrity and congruence within, no matter the circumstances, then
            our true colors should shine through as a compliment of admirable
            attributes, not a secret, darker side. A quality leader (person or
            organization) provides exceptional service, not only in pleasant
            times but also and especially when times get incredibly tough.
          </GivesBackText2>
          <GivesBackText2>
            As we continue to deal with a global pandemic and the challenges it
            imposes on our lives, it is crucial now more than ever to be that
            support for our clients, our families and our employees and
            teammates. Communicating our beliefs and finding ways to help those
            in need is vital. However, such communication equates to more than
            effective speech. Perhaps more importantly, it equates to attentive
            listening, too.
          </GivesBackText2>
          <GivesBackText2>
            <strong>Listen For What Clients Are Feeling</strong>
          </GivesBackText2>
          <GivesBackText2>
            Many of my clients have expressed fear about their recently
            purchased properties and the financial path ahead. Whether
            contemplating job security or funding for their new home or
            investment, they feel uncertainty, which is taking its toll on the
            psyche. Clients in seller positions also worry. Some have purchased
            a home and are in the midst of selling their existing property, so
            the potential of not selling in time weighs on them.
          </GivesBackText2>
          <GivesBackText2>
            As real estate professionals, now is the time to demonstrate our
            value in every possible aspect. We need to listen to our clients'
            concerns and proactively develop plans of action for their peace of
            mind before they even request the proposals. Proactive anticipation
            of issues and offering solutions are opportunities to provide real
            assistance that anyone can create when they listen.
          </GivesBackText2>
          <GivesBackText2>
            Did you know banks and lenders already have specific policies and
            procedures in place to address uncommon scenarios? Most people are
            unaware that they exist. Instead of waiting to communicate special
            policies when a crisis hits, share these strategies early in client
            discussions and planning. As you communicate the available options
            to customers or clients and reassure them of your knowledge and
            dedication, you can arrange smooth transitions for their personal
            and corporate hurdles. Revisit these plans with past clients as
            well, letting them know that you care and are ready to listen to
            their concerns, anytime.
          </GivesBackText2>
          <GivesBackText2>
            But this is only one aspect of how we assist.
          </GivesBackText2>
          <GivesBackText2>
            <strong>
              Offer Employees Encouragement Always And Additional Support As
              Needed
            </strong>
          </GivesBackText2>
          <GivesBackText2>
            Our staff members are not immune to the effects of economic
            backslides. It is discernibly a difficult time in their careers as
            they have families to provide for. Employees are the backbone of our
            business, and keeping their minds focused is crucial to their
            endured success. Continue to encourage them so that they know their
            employer is there for them, eager to listen and willing to lend a
            hand.
          </GivesBackText2>
          <GivesBackText2>
            As a company, strategize ways to incorporate effective communication
            into the assorted aspects of their current day-to-day duties.
            Offering open lines of communication with both their clients and
            loved ones allows staff members, and anyone who needs support, the
            opportunity to provide and receive comfort along with perspective
            simultaneously. Openly exchanging ideas and experiences with one
            another will prepare them for the eventual return to a more typical
            business environment and help restore post-pandemic personal
            interactions.
          </GivesBackText2>
          <GivesBackText2>
            <strong>
              Solidify Personal Relationships With Constant Contact
            </strong>
          </GivesBackText2>
          <GivesBackText2>
            Daily, I reflect on how experiencing my closest relationships with
            family and friends has been altered. Social gatherings are always
            such vital events in our lives. Whether having lunch at my parents'
            or passing the time in the company of my cousins, we worked to not
            take it for granted. That's why the gravity of our current situation
            has such a distinct impact. Making an effort to stay in constant
            contact has been a tremendously useful coping mechanism for our
            family. For example, we celebrated my birthday differently this year
            by delivering cake to numerous family households just so we could
            all sing "Happy Birthday" while safely distanced, together.
            Undoubtedly, a small gesture became a large memory-maker for this
            particular milestone, and as the years pass, I'll surely remember
            how special it was.
          </GivesBackText2>
          <GivesBackText2>
            I find it most difficult to refrain from visiting with my
            grandparents. Older people are the most vulnerable during the
            pandemic, and they are frequently the most susceptible to our
            occasional neglect, which necessitates concerted efforts to tend
            their well-being and see them as the experienced leaders in our
            lives who have helped us navigate previously uncharted waters. We
            should not ignore the imprint a simple phone call can leave on their
            day and the fortuitous joy we will likely receive from connecting
            with the individuals who love us most. It is a powerful interaction
            that, before long, we will wish we experienced more frequently.
          </GivesBackText2>
          <GivesBackText2>
            I find it most difficult to refrain from visiting with my
            grandparents. Older people are the most vulnerable during the
            pandemic, and they are frequently the most susceptible to our
            occasional neglect, which necessitates concerted efforts to tend
            their well-being and see them as the experienced leaders in our
            lives who have helped us navigate previously uncharted waters. We
            should not ignore the imprint a simple phone call can leave on their
            day and the fortuitous joy we will likely receive from connecting
            with the individuals who love us most. It is a powerful interaction
            that, before long, we will wish we experienced more frequently.
          </GivesBackText2>
          <GivesBackText2>
            More applicable today than any time in our past, remaining relevant
            to our loved ones, clients, customers and co-workers will preserve
            the bond that took years and sometimes decades to nurture, and
            reassure us all that those bonds are neither forgotten nor
            unappreciated. Communicating in our current social climate is more
            purposeful than ever before. We need to focus on what and who is
            most valuable and continue to appreciate what we have.
          </GivesBackText2>
          <GivesBackText2>
            Do not hesitate to connect with those whom you know need you most at
            this time. These experiences and perspectives propel us toward
            solutions in a time rife with obstacles. Be the voice of
            encouragement, exchange positivity and provide the many forms of
            hope that the people in your lives can utilize. Whether through a
            phone call, a video chat or a card in the mail, what may seem simple
            and ordinary can sometimes reverberate for years to come in the
            lives of those with whom we choose to involve ourselves. Let your
            true colors show in the small things and the bigger picture will
            always stay focused.
          </GivesBackText2>
          <GivesBackText2>Stay safe and remain optimistic.</GivesBackText2>
          
        </FlexDiv>
        <FlexDiv style={{ display: "flex", flexDirection: "column" }}>
          <FlexDiv
            style={{
              flex: "0.8",
              flexFlow: "column",
              paddingTop: "322.5px",
              marginLeft: "5%",
            }}
            className="longBlogSideBar relatedBlogPosts trueColorsSideBar"
          >
          <h2 className="mobileOnly">
            Continue Reading
          </h2>
            <div
              style={{ background: "#fff", maxWidth: "90%" }}
              className="socialCard"
            >
              <Link
                to="/pandemicera"
                target="_blank"
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontFamily: "CentraNo2-Medium",
                }}
                className="imgLinkWrapper"
              >
                <img
                  src={blogPostImg6}
                  style={{ width: "100%" }}
                  className="socialImg"
                />
              </Link>
              <CardText>
                <FlexDiv>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          The Pandemic-Era Trends Impacting Real Estate
                          Profitability
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/pandemicera"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          Whether you're bullish or bearish on the financial
                          impacts of Covid-19, this is undoubtedly a time to
                          profit on real estate if you know how to navigate the
                          market.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2021/12/28/the-pandemic-era-trends-impacting-real-estate-profitability/?sh=79d1c5a68d4a"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/propmanagement"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg22}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          How Property Management Can Make It Through A Pandemic
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/propmanagement"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          In this time of great hardship, let us be mindful that
                          we are indeed in this together, and commit to treating
                          one another with the respect that all humankind
                          deserves.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="https://www.forbes.com/sites/forbesrealestatecouncil/2020/07/13/how-property-management-can-make-it-through-a-pandemic/"
                          target="_blank"
                          style={{
                            margin: "1em 0",
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
                <FlexDiv>
                  <Link
                    to="/achievesmartgoals"
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color: "#000",
                      fontFamily: "CentraNo2-Medium",
                      paddingTop: "50px",
                    }}
                    className="imgLinkWrapper"
                  >
                    <img
                      src={blogPostImg3}
                      style={{ width: "100%" }}
                      className="socialImg"
                    />
                  </Link>
                  <FlexDiv style={{ flexFlow: "column", alignSelf: "center" }}>
                    <FlexDiv className="innerBlogText2">
                      <BlogTitle>
                        {" "}
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#000",
                            fontFamily: "CentraNo2-Medium",
                          }}
                        >
                          Achieve More By Setting SMART Goals
                        </Link>
                      </BlogTitle>
                      <BlogDetails style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          July 5, 2020 - Anthony Tropea
                        </Link>
                      </BlogDetails>
                      <p style={{ marginBottom: 0 }}>
                        <Link
                          to="/achievesmartgoals"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "#212121",
                            fontFamily: "CentraNo2-Book",
                          }}
                        >
                          As a business owner, evaluate these key facets of your
                          life when creating goals this year.
                        </Link>
                      </p>
                      <BlogDetails
                        style={{ marginBottom: 0, fontSize: "0.5em" }}
                      >
                        <a
                          href="http://www.forbes.com/sites/forbesrealestatecouncil/2020/01/27/achieve-more-by-setting-smart-goals/"
                          target="_blank"
                          style={{
                            textDecoration: "none",
                            color: "gray",
                          }}
                          className="readArticleForbes"
                        >
                          Read Articles on Forbes.com
                        </a>
                      </BlogDetails>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </div>
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </GivesBackWrapper>
  );
};
